import React from 'react';
import { Button, FormControl, FormErrorMessage } from '@chakra-ui/core';

const FFFSub = ({ status, isSubmitting, message }) => (
  <FormControl pt={4} isInvalid={!!message}>
    <Button variantColor="red" type="submit" isDisabled={isSubmitting} isLoading={isSubmitting}>
      Skicka in min underskrift
    </Button>
    <FormErrorMessage>{message}</FormErrorMessage>
  </FormControl>
);

export default FFFSub;
