import React from 'react';
import { Checkbox, FormControl, FormErrorMessage } from '@chakra-ui/core';
import { Field } from 'formik';

const FFField = ({ name, children }) => (
  <Field
    name={name}
    render={({ field, form }) => (
      <FormControl isInvalid={form.errors[name] && form.touched[name]} mt={4}>
        <Checkbox id={name} {...field} isChecked={field.value}>{children}</Checkbox>
        <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
      </FormControl>
    )}
  />
);

export default FFField;
