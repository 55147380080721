import React from 'react';
import styled from '@emotion/styled';
import { Heading } from '@chakra-ui/core';
import { List, ListItem, Text } from '@chakra-ui/core';
import { humanizeDate } from '../lib/humanize';

const Name = styled(Text)`
  max-width: 55%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SignatureList = ({ stats }) => (
  <>
    <Heading as="h2" size="md" textAlign={'center'} mb={4}>
      Senaste underskrifterna
    </Heading>
    <List styleType="none" pt={2} maxWidth={'320px'} mx={'auto'}>
      {stats == null ? (
        <ListItem>Laddar...</ListItem>
      ) : (
        stats.recent.map(row => (
          <ListItem
            key={row.first_name + row.created_at}
            display="flex"
            justifyContent="space-between"
            py={1}
          >
            <Name as="strong">{row.first_name}</Name>{' '}
            <Text as="span" color="gray.500">
              {humanizeDate(row.created_at)}
            </Text>
          </ListItem>
        ))
      )}
    </List>
  </>
);

export default SignatureList;
