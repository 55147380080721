import React from 'react';
import styled from '@emotion/styled';
import SignatureList from './SignatureList';
import { Heading, Box } from '@chakra-ui/core';
import { humanizeNumber } from '../lib/humanize';

const Container = styled('div')`
  position: absolute;
  margin-top: -3rem;

  left: 16px;
  right: 16px;
  top: 0;
  transform: translate(0, -50%);

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    right: 0;
    left: unset;
    top: 0;
    transform: translate(0, -4rem);
  }

  > div {
    width: 100%;
    max-width: 340px;
    background: #fff;
    text-align: center;

    p {
      text-transform: uppercase;
      color: #777;
      font-weight: 700;
    }
  }
`;

const SignatureCounter = ({ stats }) => (
  <Container>
    <Box
      px={[4, 4, 12]}
      pt={[2, 2, 6]}
      pb={[4, 4, 10]}
      m={'auto'}
      color={'#1A202C'}
    >
      <Heading as="h2" size="2xl">
        {stats == null ? 'Laddar...' : humanizeNumber(stats.count)}
      </Heading>
      <p>underskrifter hittills</p>
      <Box display={['none', 'none', 'block']}>
        <SignatureList stats={stats} />
      </Box>
    </Box>
  </Container>
);

export default SignatureCounter;
