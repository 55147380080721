import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import styled from '@emotion/styled';
import { Heading } from '@chakra-ui/core';
import MaxWidth from './MaxWidth';
import BackgroundImage from 'gatsby-background-image';

const Header = styled(BackgroundImage)`
  position: relative;
  width: 100%;
  height: 60vh;
  max-height: 320px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #333;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    max-height: 400px;
    > div {
      align-items: center;

      text-align: center;
    }
  }

  h1 {
    font-size: 2rem;
    text-transform: uppercase;
    width: 50%;

    @media (max-width: 1900px) {
      width: 66%;
    }

    @media (max-width: 768px) {
      font-size: 1.5rem;
      width: 100%;
    }
  }

  /* haXx to fix gatsby-image mobile proportions */
  @media (max-width: 979px) {
    .gatsby-image-wrapper > div {
      padding-bottom: 100% !important;
    }
  }
`;

const query = graphql`
  query {

    headerImageDesktop: file(relativePath: { eq: "header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight:900) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    logoImage: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 160, height: 160) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;

const Hero = ({ title, stats }) => (
  <StaticQuery
    query={query}
    render={data => {
      const headerImages = [
        'linear-gradient(180deg, rgba(0, 0, 0, 0.125) 0%, rgba(0, 0, 0, 0.5) 100%)',
        data.headerImageDesktop.childImageSharp.fluid,
      ];

      return (
        <Header fluid={headerImages}>
          <MaxWidth>
            <Img
              fixed={data.logoImage.childImageSharp.fixed}
              className="header-logo"
            />
            <Heading
              as="h1"
              size="xl"
              my={6}
              textAlign={['center', 'center', 'left']}
              color="white"
            >
              {title}
            </Heading>
          </MaxWidth>
        </Header>
      );
    }}
  />
);

export default Hero;
