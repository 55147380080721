import React from 'react';
import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage
} from '@chakra-ui/core';
import {Field} from 'formik'

const FFField = ({ label, type, name }) => (
  <Field
    name={name}
    render={({ field, form }) => (
      <FormControl isInvalid={form.errors[name] && form.touched[name]} my={2}>
        <FormLabel htmlFor={name}>{label}</FormLabel>
        <Input id={name} type={type} {...field} />
        <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
      </FormControl>
    )}
  />
);

export default FFField;
