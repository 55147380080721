import React, { useState } from 'react';
import { Box } from '@chakra-ui/core';
import { Heading } from '@chakra-ui/core';
import { Text } from '@chakra-ui/core';
import MaxWidth from './MaxWidth';
import styled from '@emotion/styled';

const Content = styled('div')`
  position: relative;
  overflow: hidden;
  max-height: 230px;
  transition: 0.3s ease max-height;

  ${({ showAll }) =>
    showAll
      ? `max-height: 1500px;`
      : `
      &::after {
        content: '';
        width: 100%;
        height: 120px;
        background: linear-gradient(to bottom, rgba(226,232,240,0) 0%, rgba(226,232,240,0.8) 67%, rgba(226,232,240,1) 100%);
        position: absolute;
        bottom: 0;
        left: 0;
      }
    `}
`;

const ReadMore = styled('button')`
  background: transparent;
  box-shadow: none;
  border: 0;
  width: 100%;
  color: #777;
  font-weight: 700;
  padding: 12px;

  &:hover {
    color: #444;
  }
`;

const Article = () => {
  const [showAll, setShowAll] = useState(false);
  return (
    <Box
      as="section"
      id="article"
      bg="gray.200"
      w="100%"
      p={4}
      py={12}
      color="black"
    >
      <MaxWidth>
        <Box mr={[0, 0, 0, '360px']} maxW={'580px'}>
          <Content showAll={showAll}>
            <Heading>Bakgrund</Heading>
            <Text pt={4}>
              <strong>
                Politikerna måste hålla sina löften och agera i klimatkrisen.
                Skriv under för att visa politikerna att de måste börja ta sitt
                ansvar.
              </strong>
            </Text>
            <Text pt={4}>
              Vi befinner oss i en kris. En kris som beror på åratal av mänsklig
              påverkan på klimatet som ingen gjort tillräckligt mycket åt. En
              kris som är så allvarlig att vi skolungdomar behöver lämna vår
              utbildning för att kräva att någonting görs. För nu är det bråttom
              och om ingenting görs riskerar vi att inte få någon framtid alls
              att studera inför. År 2020 måste utsläppskurvan sänkas drastiskt
              för att vi ska ha en chans att hålla den globala uppvärmningen
              under 1,5 grader.
            </Text>
            <Text pt={4}>
              År 2020 ska Parisavtalet börja gälla och då måste vår politik gå i
              linje med avtalet. Under ett års tid har miljontals skolelever
              världen över strejkat. Vi hoppades att det skulle räcka. Men
              utsläppen ökar fortfarande och vår politik går inte i linje med
              Parisavtalet. Isarna smälter, skogarna brinner, havsytan stiger
              och vår framtid tas fortfarande ifrån oss. Därför ber vi er om
              hjälp. Visa att även vuxna kräver
              förändring. Ställ er bakom rörelsen genom att skriva under
              våra krav.
            </Text>
          </Content>
          {!showAll && (
            <ReadMore onClick={() => setShowAll(true)}>Läs mer</ReadMore>
          )}
        </Box>
      </MaxWidth>
    </Box>
  );
};

export default Article;
