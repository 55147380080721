import React from "react";
import { Box } from "@chakra-ui/core";
import { Text } from "@chakra-ui/core";
import styled from "@emotion/styled";

import Form from "./Form/Form";

import Hero from "./Hero";
import SignatureList from "./SignatureList";
import Demands from "./Demands";
import Article from "./Article";
import MaxWidth from "./MaxWidth";

const App = ({ stats, reloadStats }) => (
  <>
    <Hero title="Namninsamling för agerande i klimatkrisen" />
    <Demands stats={stats}  />
    <Article />

    <MaxWidth>
      <Box w="100%" maxWidth={'480px'} py={12} mx={'auto'}>
        <Form onSubmit={reloadStats} />
      </Box>
    </MaxWidth>

    <Box bg="gray.200" w="100%" p={4} pt={10} pb={12} color="black">
      <MaxWidth>
        <SignatureList stats={stats} />
      </MaxWidth>
    </Box>

    <Box w="100%" p={4}>
      <Text textAlign={'center'}>
        <a href="http://fridaysforfuture.se">Fridays for Future Sverige</a> <br/>
        webmaster@fridaysforfuture.se
      </Text>
    </Box>
  </>
)

export default App;
