import * as yup from 'yup';

export default yup.object().shape({
  first_name: yup
    .string()
    .min(2, 'Minst två tecken')
    .max(50, 'Max 50 tecken')
    .required('Du måste ange detta fält'),
  last_name: yup
    .string()
    .min(2, 'Minst två tecken')
    .max(50, 'Max 50 tecken')
    .required('Du måste ange detta fält'),

  postal_code: yup
    .string()
    .transform(val => val && val.replace(/\s/gi, ''))
    .matches(/^\d{5}$/, 'Du måste ange ett giltigt postnummer')
    .required('Du måste ange detta fält'),

  email: yup
    .string()
    .email('Du måste ange en giltig email')
    .required('Du måste ange detta fält'),

  public: yup.boolean()
});
