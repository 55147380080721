const sec = 1000;
const min = sec * 60;
const hour = min * 60;
const day = hour * 24;

export const humanizeDate = date => {
  const timestamp = new Date(date).getTime();
  const duration = Date.now() - timestamp;

  if (duration < 0) {
    return 'snart';
  } else if (duration < 1.5 * min) {
    return 'nyss';
  } else if (duration < 0.8 * hour) {
    return `${Math.round(duration / min)} min sedan`;
  } else if (duration < 0.8 * day) {
    return `${Math.round(duration / hour)} h sedan`;
  } else {
    const count = Math.round(duration / day);
    return count > 1 ? `${count} dagar sedan` : `${count} dag sedan`;
  }
};

export const humanizeNumber = number =>
  number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
