import React, { useEffect, useState, useRef, useCallback } from "react";
import { ThemeProvider, CSSReset, theme } from "@chakra-ui/core";
import { Global, css } from '@emotion/core'
import customTheme from "../theme";
import App from "../components/App";
import SEO from "../components/SEO";

console.info('Byggt av #softwaredevelopersforfuture');

const Index = () => {
  const timer = useRef();
  const [stats, setStats] = useState(null);

  const reloadStats = useCallback(async function self() {
    const res = await fetch("/api/stats");
    const newStats = await res.json();
    setStats(newStats);

    clearTimeout(timer.current);
    timer.current = setTimeout(self, 5000);
  }, []);

  useEffect(() => {
    reloadStats();
    return () => clearTimeout(timer.current);
  }, [reloadStats]);

  return (
    <ThemeProvider theme={customTheme}>
      <Global
        styles={css`
          body {
            font-family: "sofia-pro", sans-serif;
          }
        `}
      />
      <main>
        <SEO />
        <CSSReset />
        <App stats={stats} reloadStats={reloadStats} />
      </main>
    </ThemeProvider>
  );
}

export default Index;
