import React from 'react';
import Field from './Field';
import Check from './Check';
import Submit from './Submit';
import ThankYou from './ThankYou';

import { Text, Heading, Link } from '@chakra-ui/core';

import { Formik } from 'formik';
import schema from '../../lib/schema';
import PrivacyModal from '../PrivacyModal';

const Form = ({ onSubmit }) => {
  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        first_name: '',
        last_name: '',
        email: '',
        postal_code: '',
        public: true
      }}
      initialStatus={{}}
      onSubmit={async (values, actions) => {
        try {
          const response = await fetch('/api/submit', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
          });
          const data = await response.json();
          actions.setSubmitting(false);

          console.error(data);

          if (data.success) {
            actions.setStatus({ success: true });
          } else {
            if (typeof data.error) {
              actions.setStatus({ success: false, message: data.error });
            } else {
              actions.setStatus({ success: false, message: '' });
            }
            if (data.errors) {
              actions.setErrors(data.errors);
              console.error(data);
            }
          }
        } catch (err) {
          console.error(err);

          actions.setSubmitting(false);
          actions.setErrors(err);
          actions.setStatus({ success: false, message: err });
        }
      }}
      render={({ handleSubmit, status, isSubmitting, resetForm }) => {
        if (status.success) {
          return <ThankYou reset={resetForm} />;
        } else {
          return (
            <form onSubmit={handleSubmit}>
              <Heading id="sign" mb={4}>
                Din underskrift
              </Heading>
              <Field type="text" label="Förnamn" name="first_name" />
              <Field type="text" label="Efternamn" name="last_name" />
              <Field type="email" label="E-post" name="email" />
              <Field type="phone" label="Postnummer" name="postal_code" />
              <Check name="public">Visa mitt namn på hemsidan</Check>
              <Submit isSubmitting={isSubmitting} message={status.message} />
              <Text py={2} fontSize={14}>
                Genom att klicka på knappen nedan bekräftar du att dina
                personuppgifter får hanteras enligt vår <PrivacyModal /> och att
                ditt namn läggs till listan som ska förmedlas till riksdagen
                efter den 27e september.
              </Text>
            </form>
          );
        }
      }}
    />
  );
};

export default Form;
