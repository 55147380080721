import React from 'react';
import { Icon, Heading, Button, Text, Flex } from '@chakra-ui/core';
import styled from '@emotion/styled';
import css from '@emotion/css';
import { border } from 'styled-system';

const ShareButton = styled(Button)`
  display: flex;
  max-width: 320px;
  width: 100%;
`;

const ThankYou = ({ status, isSubmitting, message }) => (
  <Flex direction="column" align="center">
    <Icon
      my={4}
      name="check"
      size="96px"
      color="green"
      css={css`
        border: 2px solid green;
        border-radius: 50%;
        color: #1b743f;
        padding: 26px 24px 20px;
      `}
    />
    <Heading mt={4} color="#1B743F">
      Tack för din underskrift
    </Heading>
    <Text mt={4} mb={6} textAlign="center">
      Varje underskift är viktig. Dela gärna denna namninsamling med dina vänner så att vi kan nå så många som möjligt!
    </Text>
    <ShareButton
      as="a"
      variantColor={'facebook'}
      href="https://www.facebook.com/sharer/sharer.php?u=https://skrivunder.fridaysforfuture.se"
      target="_blank"
      rel="noopener"
      aria-label="Dela på Facebook"
      my={1}
    >
      Dela på Facebook
    </ShareButton>
    <ShareButton
      as="a"
      variantColor={'twitter'}
      href="https://twitter.com/share?url=https://skrivunder.fridaysforfuture.se%3Fsource%3Dtwitter-share-email-button%26time%3D1568113251%26utm_source%3Dtwitter&amp;via=FFF_Sweden&amp;text="
      target="_blank"
      rel="noopener"
      aria-label="Dela på Twitter"
      my={1}
    >
      Dela på Twitter
    </ShareButton>
    <ShareButton
      as="a"
      variantColor={'whatsapp'}
      href="mailto:?subject=Namninsamling för Fridays For Future&amp;body=https://skrivunder.fridaysforfuture.se"
      target="_self"
      rel="noopener"
      aria-label="Dela via mejl"
      my={1}
    >
      Dela via mejl
    </ShareButton>
  </Flex>
);

export default ThankYou;
