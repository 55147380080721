import React from 'react';
import {
  Button,
  Link,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@chakra-ui/core';
import Privacy from './Privacy';

export default ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const open = e => {
    setIsOpen(true);
    e.preventDefault();
    e.stopPropagation();
  };
  const close = () => setIsOpen(false);

  return (
    <>
      <Link
        href="/personuppgifter"
        color="red.800"
        textDecoration="underline"
        target="_blank"
        onClick={open}
      >
        personuppgiftspolicy
      </Link>
      <Modal size="xl" isOpen={isOpen} onClose={close}>
        <ModalHeader>Personuppgiftspolicy</ModalHeader>
        <ModalBody>
          <Privacy />
        </ModalBody>
        <ModalFooter>
          <Button onClick={close}>Stäng</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
