import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled("div")`
  padding: 0 16px;
  width: 100%;
  margin: auto;
  position: relative;
  max-width: 940px;
`;

const MaxWidth = ({ children, thinDesktop }) => (
  <Wrapper thinDesktop={thinDesktop}>{children}</Wrapper>
);

export default MaxWidth;
