import React from 'react';

import { Box } from '@chakra-ui/core';
import { Heading } from '@chakra-ui/core';
import { List, ListItem } from '@chakra-ui/core';
import { Button } from '@chakra-ui/core';
import MaxWidth from './MaxWidth';
import SignatureCounter from './SignatureCounter';
import styled from '@emotion/styled';

const DemandList = styled(List)`
  counter-reset: demand-counter;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  li {
    width: 100%;
    counter-increment: demand-counter;
    position: relative;
    margin-bottom: 24px;

    @media (min-width: 769px) {
      flex: 1 1 auto;
      max-width: 360px;

      :nth-child(odd) {
        padding-right: 24px;
      }
    }
    @media (min-width: 960px) {
      flex: 0 0 50%;
    }
    &::before {
      content: counter(demand-counter);
      float: left;
      background: #ba5161;
      color: #fff;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      text-align: center;
      margin-right: 8px;
      line-height: 1.4;
    }

    :nth-child(odd) {
      &::before {
        line-height: 1.45;
      }
    }
  }
`;

const smoothScroll = e => {
  e.preventDefault();
  document.querySelector('#sign').scrollIntoView({
    behavior: 'smooth'
  });
};

const Demands = ({ stats }) => (
  <section id="demands">
    <Box bg="green.500" w="100%" p={4} py={12} color="white">
      <MaxWidth>
        <Box mr={[0, 0, '360px']}>
          <SignatureCounter stats={stats} />
          <Heading pt={[4, 4, 0]} pb={[5, 8]}>
            Vi kräver att
          </Heading>
          <DemandList spacing={3} as="ol" styleType="decimal">
            <ListItem>
              Sverige ska föra en politik i linje med Parisavtalet.
            </ListItem>
            <ListItem>
              Den globala uppvärmningen ska hållas under 1,5 grader.
            </ListItem>
            <ListItem>Politiken ska lyssna på forskarna.</ListItem>
            <ListItem>Klimaträttvisa ska råda.</ListItem>
          </DemandList>
        </Box>
        <a href="#sign" onClick={smoothScroll}>
          <Button variantColor="red" mt={2}>
            Skriv under
          </Button>
        </a>
      </MaxWidth>
    </Box>
  </section>
);

export default Demands;
