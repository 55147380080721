import { theme } from "@chakra-ui/core";

export default {
  ...theme,
  breakpoints: ["30em", "48em", "62em", "80em"],
  fonts: {
    heading: '"sofia-pro", sans-serif',
    body: '"sofia-pro", sans-serif',
    mono: "Menlo, monospace"
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    "2xl": "1.5rem",
    "3xl": "1.875rem",
    "4xl": "2.25rem",
    "5xl": "3rem",
    "6xl": "4rem"
  },
  colors: {
    ...theme.colors,
    green: {
      50: "#e6f0ea",
      100: "#ccdfd4",
      200: "#adcdba",
      300: "#8ab79c",
      400: "#5c9b76",
      500: "#1a733f",
      600: "#176737",
      700: "#145a30",
      800: "#104927",
      900: "#0b331b"
    },
    red: {
      50: "#d9a0a8",
      100: "#d3909a",
      200: "#cc808c",
      300: "#c6707d",
      400: "#c0606f",
      500: "#ba5161",
      600: "#aa4a59",
      700: "#994350",
      800: "#883b47",
      900: "#77343e"
    }
  }
};
